<template>
    <section>
        <template>
            <div class="row mx-0 px-3 pl-5 my-1 border-bottom align-items-center" style="height:50px;">
                <div class="col px-1">
                    <p class="f-600 text-general">Condiciones vencimientos</p>
                </div>
            </div>
            <div class="custom-scroll overflow-auto px-4 pl-5">
                <div class="row mx-0 mt-3">
                    <div class="col px-0">
                        <ValidationObserver ref="validacion2" v-slot="{ valid }">
                            <div class="row mx-0 mt-1">
                                <div class="col">
                                    <p class="text-general f-13 pl-3">
                                        Desde (dias)
                                    </p>
                                    <ValidationProvider v-slot="{errors}" name="desde">
                                        <el-input v-model="model.desde" placeholder="Valor" class="w-100" style="font-size:14px;" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col">
                                    <p class="text-general f-13 pl-3">
                                        Hasta (dias)
                                    </p>
                                    <ValidationProvider v-slot="{errors}" name="hasta">
                                        <el-input v-model="model.hasta" placeholder="Valor" class="w-100" style="font-size:14px;" :disabled="model.noLimite" />
                                        <el-checkbox v-model="model.noLimite" @change="model.hasta = 0">
                                            En adelante
                                        </el-checkbox>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col">
                                    <p class="text-general f-13 text-center">
                                        Comisión (%)
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="decimal:2|max:100" name="pago">
                                        <input-miles v-model="model.porcentaje" :money-options="configComision" placeholder="Valor" class="w-100" style="font-size:14px;" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-auto pt-3">
                                    <el-tooltip placement="bottom" content="Crear Comisión" effect="light">
                                        <div class="bg-general text-white br-10 px-1 py-1 mt-1" @click="crearCondicionPago(valid)">
                                            <i class="icon-plus f-18 cr-pointer" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="col-1" />
                            </div>
                        </ValidationObserver>
                        <div v-for="(comision, c2) in condiciones" :key="c2" class="row mx-0 mt-2">
                            <div class="col">
                                <el-input v-model="comision.desde" :disabled="comision.editar" placeholder="%" class="w-100" style="font-size:14px;" />
                            </div>
                            <div class="col">
                                <el-input v-if="comision.hasta == null || comision.hasta == 0" disabled value="En Adelante" class="w-100  br-10" style="font-size:14px;" />
                                <el-input v-else v-model="comision.hasta" :disabled="comision.editar" placeholder="%" class="w-100" style="font-size:14px;" />
                                <el-checkbox v-show="comision.hasta == null || comision.hasta == 0" v-model="comision.noLimite" :disabled="comision.editar" @change="comision.hasta = 0">
                                    En adelante
                                </el-checkbox>
                            </div>
                            <div class="col">
                                <input-miles v-model="comision.porcentaje" :money-options="configComision" :disabled="comision.editar" placeholder="%" class="w-100" style="font-size:14px;" />
                            </div>
                            <div class="col-auto pt-2">
                                <i v-if="comision.editar" class="icon-pencil-outline f-20 cr-pointer" @click="comision.editar = !comision.editar" />
                                <i v-else class="icon-ok-circled-outline text-success f-20 cr-pointer" @click="editarCondicionPago(comision)" />
                            </div>
                            <div class="col-1 pt-2">
                                <i v-if="comision.editar" class="icon-trash-empty f-20 text-general2 cr-pointer" @click="eliminar(comision.id)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- Partials -->
        <modal-duplicar-comision ref="modalDuplicarComision" />
        <modalEliminar ref="modalEliminar" titulo="Eliminar comisión" mensaje="¿Desea eliminar la comisión?"
                       @eliminar="deleteCondicionPago"
        />
        <modalEliminar ref="modalEliminarCondicion" titulo="Eliminar comisiones de la condición"
                       mensaje="¿Desea eliminar las comisiones?" @eliminar="deleteCondicionPagoCondicion"
        />
        <modalEliminar ref="modalEliminarCedis" titulo="Eliminar comisiones del Cedis"
                       mensaje="¿Desea eliminar todas las comisiones del Cedis?" @eliminar="deleteCondicionPagoCedis"
        />
        <modal ref="modalFavorito" titulo="Cambiar condición Cedis" @guardar="editarCondicionCedis">
            <slot>
                <div class="row justify-center">
                    <el-checkbox v-model="radio" class="check-red">
                        Cambiar condición por defecto a los {{ $config.vendedor }}
                    </el-checkbox>
                </div>
            </slot>
        </modal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Condiciones from "~/services/configurar/admin/condiciones";
export default {
    components: {
        modalDuplicarComision: () => import('./partials/modalDuplicarComision')
    },
    data(){
        return {
            model: {
                desde: 0,
                hasta: 0,
                pago: 0,
                noLimite: false
            },
            text: 'Editar clasificaciòn de descuentos',
            cedis: [],
            radio: false,
            id_cedis: null,
            idEliminar: null,
            idCondicion: null,
            condiciones: [],
            configComision: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' %',
                precision: 2,
                masked: false
            },
        }
    },
    computed: {
        ...mapGetters({
            monedas: 'cedis/monedas',
        }),
        nombreCedis(){
            return this.cedis.find(el => el.id == this.id_cedis).nombre || 'Sin cedis'
        },
        estadoCedis(){
            return this.cedis.find(el => el.id == this.id_cedis).estado == 1
        },
        configComisionCedis(){
            let precision = this.cedis.find(el => el.id == this.id_cedis)?.decimales

            return {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 0,
                masked: false
            }
        }
    },
    mounted(){
        this.listarCedis()
    },
    methods: {
        async listarCedis(){
            try {
                const { data } = await Condiciones.getCedis()
                this.cedis = data.data
                this.id_cedis = this.cedis[0].id
                this.listarCondicionesPagos()
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarCondicionesPagos(){
            try {
                const params = {
                    id_cedis: this.id_cedis,
                }
                const { data } = await Condiciones.listarCondicionesVencimientos(params)
                data.data.forEach(el => {
                    el.model = {}
                    el.editar = true;
                    if(!el.hasta) el.noLimite = true
                })
                this.condiciones = data.data
            } catch (error){
                console.error(error);
            }
        },
        async crearCondicionPago(valid){
            try {
                if (!valid) return this.notificacion('', 'Verifique los campos')
                if(this.model.desde >= this.model.hasta && !this.model.noLimite ) return this.notificacion('', 'El campo hasta debe ser mayor al campo desde', 'warning')
                const payload = {
                    desde: this.model.desde ,
                    hasta: this.model.noLimite ? 10000 : this.model.hasta,
                    porcentaje: this.model.porcentaje ,
                    noLimite: this.model.noLimite
                }
                const { data } = await Condiciones.postCondicionesVencimientos(payload)
                if (data.error){
                    return this.notificacion('', data.error, 'warning')
                }
                this.notificacion('', 'Creado correctamente', 'success')
                this.listarCondicionesPagos()
                this.model.desde = 0;
                this.model.hasta = 0;
                this.model.porcentaje = 0;
                this.model.noLimite = false
                this.$refs.validacion2.reset()
            } catch (e){
                this.error_catch(e)
            }
        },
        async editarCondicionPago(row){
            try {
                if(row.desde >= row.hasta && !row.noLimite ) return this.notificacion('', 'El campo hasta debe ser mayor al campo desde', 'warning')
                const payload = {
                    desde: row.desde ,
                    hasta: row.noLimite ? 10000 : row.hasta,
                    porcentaje: row.porcentaje ,
                    noLimite: row.noLimite,
                    id: row.id
                }
                const { data } = await Condiciones.putCondicionesVencimientos(payload)
                if (data.error){
                    return this.notificacion('', data.error, 'warning')
                }
                row.editar = !row.editar
                this.notificacion('', 'Editado correctamente', 'success')
                this.listarCondicionesPagos()
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            if (id){
                this.idEliminar = id
            }
            this.$refs.modalEliminar.toggle()
        },
        async deleteCondicionPago(){
            try {
                const { data } = await Condiciones.deleteCondicionesVencimientos(this.idEliminar)
                this.notificacion('', 'Eliminado correctamente', 'success')
                this.listarCondicionesPagos()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminarCondicion(condicion){
            if (condicion.id){
                this.idCondicion = condicion.id
            }
            this.$refs.modalEliminarCondicion.toggle()
        },
        async deleteCondicionPagoCondicion(){
            try {
                const { data } = await Condiciones.deleteCondicionPagoCondicion(this.id_cedis, this.idCondicion)
                this.notificacion('', data.mensaje, 'success')
                this.listarCondicionesPagos()
                this.$refs.modalEliminarCondicion.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminarCedis(){
            this.$refs.modalEliminarCedis.toggle()
        },
        async deleteCondicionPagoCedis(){
            try {
                const { data } = await Condiciones.deleteCondicionPagoCedis(this.id_cedis)
                this.notificacion('', data.mensaje, 'success')
                this.listarCondicionesPagos()
                this.$refs.modalEliminarCedis.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        favoritoCedis(id){
            if (id){
                this.idCondicion = id
                this.radio = false
                this.$refs.modalFavorito.toggle()
            }
        },
        async editarCondicionCedis(){
            try {
                let params = {
                    id_condicion: this.idCondicion,
                    id_cedis: this.id_cedis,
                    leechero: this.radio
                }
                const { data } = await Condiciones.editarCondicionCedis(params)
                this.notificacion('', data.mensaje, 'success')
                this.listarCondicionesPagos()
                this.$refs.modalFavorito.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        crear(valid, i){
            this.$refs.validacion2[i].reset()
        },
        duplicarProducto(){
            let cedisValidos = this.cedis.filter(el => el.id != this.id_cedis && el.id != 0)
            cedisValidos.forEach(el => { el.checked = false })
            this.$refs.modalDuplicarComision.toggle(cedisValidos, this.id_cedis)
        },
        goCondiciones(){
            this.$router.push({ name: 'admin.conf.condiciones' })
        },
        gestionUltimaComision(event, condicion){
            if(event){
                condicion.noLimite = 0
            }
        }
    }
}
</script>

<style lang="css" scoped>
.orange-star {
	color: #FF9D32;
}

.nombre-condicion {
	height: 45px;
	background-color: #E9ECEF;
	color: var(--text-general) !important;
	font-size: 14px;
}
</style>
