import { render, staticRenderFns } from "./indexComisiones.vue?vue&type=template&id=4403a53c&scoped=true"
import script from "./indexComisiones.vue?vue&type=script&lang=js"
export * from "./indexComisiones.vue?vue&type=script&lang=js"
import style0 from "./indexComisiones.vue?vue&type=style&index=0&id=4403a53c&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4403a53c",
  null
  
)

export default component.exports