<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 border-bottom align-items-center" style="height:50px;">
            <div class="col px-1">
                <p class="f-600 text-general">Configuracion de las Comisiones</p>
            </div>
            <div class="d-flex ml-auto h-100">
                <router-link :to="{name: 'admin.conf.comisiones-condiciones'}">
                    <div class="ml-auto h-100 d-middle-center cr-pointer" :class="show? 'border-b-red text-general-red':'text-general'" style="width:170px;">
                        Pago porcentaje
                    </div>
                </router-link>
                <router-link :to="{name: 'admin.conf.condiciones'}">
                    <div class="h-100 d-middle-center cr-pointer" :class="!show? 'border-b-red text-general-red':'text-general'" style="width:170px;">
                        Pago valor
                    </div>
                </router-link>
            </div>
        </div>

        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 218px);">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            text: 'Editar clasificaciòn de descuentos',
        }
    },
    computed:{
        show(){
            const name = this.$route.name
            if(name == "admin.conf.comisiones-condiciones")return true
            return false
        }
    },
}
</script>


<style lang="css" scoped>
.orange-star {
	color: #FF9D32;
}


.nombre-condicion {
	height: 35px;
	background-color: #E9ECEF;
	color: var(--text-general) !important;
	font-size: 14px;
}

.tab{
    color: var(--text-general);
    text-decoration: none !important;
}
</style>
