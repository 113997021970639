import axios from "axios";

const API = "condiciones-leechero";

const condiciones = {
    listarCondiciones: (params = {}) => axios.get(`${API}/listar`, { params }),
    getCedis: () => axios.get(`${API}/listar-cedis`),
    crearCondicion: (params) => axios.post(`${API}/condicion`, params),
    editarCondicion: (params) => axios.put(`${API}/condicion/${params.id}`, params),
    deleteCondicion: (id) => axios.delete(`${API}/condicion/${id}`),

    //pagos
    listarCondicionesPagos: (params = {}) => axios.get(`${API}/listar-condiciones-pagos`, { params }),
    crearCondicionPago: (params) => axios.post(`${API}/condicion-pago`, params),
    editarCondicionPago: (params) => axios.put(`${API}/condicion-pago/${params.id}`, params),
    deleteCondicionPago: (id) => axios.delete(`${API}/condicion-pago/${id}`),
    deleteCondicionPagoCondicion: (idCedis, idCondicion) => axios.delete(`${API}/condicion-pago-condicion/${idCedis}/${idCondicion}`),
    deleteCondicionPagoCedis: (idCedis) => axios.delete(`${API}/condicion-pago-cedis/${idCedis}`),
    editarCondicionCedis: (params) => axios.post(`${API}/condicion-cedis`, params),
    duplicarCondicionCedis: (params) => axios.post(`${API}/duplicar-condiciones`, params),

	//categorias
	listarCategorias: () => axios.get(`landing/seccion/select-categorias`),

	//vencimientos
	listarCondicionesVencimientos: () => axios.get(`${API}/vencimientos`),
	postCondicionesVencimientos: (payload) => axios.post(`${API}/vencimientos`, payload),
	putCondicionesVencimientos: (payload) => axios.put(`${API}/vencimientos`, payload),
	deleteCondicionesVencimientos: (id) => axios.delete(`${API}/vencimientos/${id}/eliminar`),
};

export default condiciones;
